// Colors ---------------
$white: #ffffff;

$black: #0e1133;
$black-2: #121317;
$black-3: #000320;



// grey colors
$grey:#f3f4f8;
$grey-2:#edeef3;
$grey-3:#dbdfe9;
$grey-4:#c9ccd4;
$grey-5:#f5f6fa;
$grey-6:#e2ece9;
$grey-7:#f6f7fb;
$grey-8:#d8dae3;
$grey-9:#7a797f;
$grey-11:#eeedf2;
$grey-12:#f6f6f7;
$grey-13:#8e8c94;


// footer

$footer-bg: #0e1133;



// basic color
$e-blue: #2b4eff;
$e-blue-2: #3b60ff;
$e-blue-3: #b8dde3;
$e-blue-4: #2d69f0;
$e-blue-5: #516eff;
$e-blue-6: #5744cb;
$e-yellow: #ffb352;
$e-yellow-2: #ff9415;
$e-yellow-3: #ffa507;
$e-pink: #fc4389;
$e-pink-2: #f2277e;
$e-pink-3: #dd246e;
$e-pink-4: #fc4d93;
$e-pink-5: #ff4690;
$e-orange: #ff652e;
$e-orange-2: #fa7919;
$e-orange-3: #ff8f21;
$e-orange-4: #f7641e;
$e-purple: #b128ff;
$e-purple-2: #8007e6;
$e-purple-3: #8330fe;
$e-skyblue: #0fa0dd;
$e-skyblue-2: #14c0da;
$e-green: #30a820;
$e-green-2: #0cae74;
$e-brown: #960443;


// text colors
$body-text-color: #6d6e75;

$e-text-1: #8c8faa;
$e-text-2: #474956;
$e-text-3: #6d6e75;
$e-text-4: #53545b;
$e-text-5: #a1a2ab;
$e-text-6: #83848a;
$e-text-7: #8d8e9a;


//border colors
$border:#eef0f6;
$border-2:#f0f0f5;
$border-3:#e0e3ed;
$border-4:#d7d9e3;
$border-5:#1c1f3f;
$border-6:#e0e1e8;
$border-7:#d2d3dc;
$border-8:#edeef2;



// Heading Color
$heading-color: #0e1133;


// Soft color
$black-soft:#000;
