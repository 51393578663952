@use '../abstract' as *;

/*----------------------------------------*/
/*  12. ABOUT CSS START
/*----------------------------------------*/

.about{
    &__thumb{
        & img{
            width: 370px;
            @include border-radius(6px);
            @media #{$xs}{
                width: 100%;
            }
        }
        &-wrapper{
            position: relative;
        }
    }
    &__banner{
        
        @media #{$xs}{
            margin-top: -25px;
        }
        & img{
            @include border-radius(6px);
            max-width: 240px;
        }
    }
    &__content{
        @media #{$lg}{
            padding-left: 55px;
        }
        @media #{$md}{
            margin-top: 50px;
            padding-left: 0px;
        }
        @media #{$sm}{
            margin-top: 50px;
            padding-left: 0px;
        }
        @media #{$xs}{
            margin-top: 50px;
            padding-left: 0px;
            padding-right: 0;
        }
    }
    &__student{
        @media #{$xs}{
            margin-top: 20px;
            margin-left: 0;
        }
         & img{
            height: 36px;
            width: 36px;
            @include border-radius(50%);
            border: 2px solid $white;
            @include box-shadow(0px 10px 20px 0px rgba(0, 5, 52, 0.1));
            & + img{
                margin-left: -12px;
            }
        }
        & p{
            margin-top: 4px;
            margin-bottom: 0;
            & span{
                font-weight: 600;
                color: $black;
            }
        }
    }
    &__review{
        position: absolute;
        left: -25px;
        top: 100px;
        @include transform(rotate(90deg));

        & h5{
            font-size: 16px;
            font-weight: 400;
            color: $e-text-3;
            margin-bottom: 0;
            & span{
                font-weight: 600;
                color: $black;
            }
        }
    }
    &__list{
        & ul{
            & li{
                font-size: 16px;
                font-weight: 500;
                color: $black;
                margin-bottom: 5px;
                & i{
                    font-size: 16px;
                    color: $e-green;
                    padding-right: 9px;
                }
            }
        }
    }
}

.about__thumb.ml-100 {
    @media #{$xs}{
        margin-left: 0;
    }
}
.about__review {
    @media #{$xs}{
        display: none;
    }
}
.about__banner.mt--210 {
	@media #{$xs}{
        display: none;
    }
}
.about__student {
	@media #{$xs}{
        margin-top: 30px;
    }
}
.about__thumb img {
	@media #{$sm}{
        width: 100%;
    }
}